import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { BaseButton } from "./styledComponents"

const ModalContainer = styled.div`
  position: fixed;
  z-index: 2002;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 784px;
  height: 432px;
  background-color: #fff;
  padding: 18px 0;

  @media (max-width: 996px) {
    display: none !important;
  }
`

const ModalBackground = styled.div`
  position: fixed;
  z-index: 2001;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #323e5bb3 0% 0% no-repeat padding-box;
  border: none;
  cursor: auto;

  @media (max-width: 996px) {
    display: none !important;
  }
`

const ModalClose = styled.div`
  position: absolute;
  right: 48px;
  top: 34px;
  width: 22px;
  height: 22px;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 28px;
    width: 4px;
    background-color: #323e5b;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const ModalTitle = styled.h2`
  font: normal normal bold 51px/62px Montserrat;
  letter-spacing: 0px;
  color: #f0565e;
  margin: unset;
`

const ModalContent = styled.div`
  width: 100%;
  padding: 25px 102px;
  text-align: center;
  font: normal normal bold 32px/39px Montserrat;
  letter-spacing: 0px;
  color: #323e5b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const Popup = () => {
  const [visible, setVisible] = useState(false)
  let pop_status =
    typeof window !== "undefined" ? localStorage.getItem("pop_status") : null

  const handleToggle = () => {
    setVisible(false)
  }

  useEffect(() => {
    let componentMounted = true

    if (typeof window !== "undefined") {
      document.querySelector("body").addEventListener("mouseleave", () => {
        if (componentMounted) {
          if (pop_status === null) {
            setVisible(true)
            localStorage.setItem("pop_status", 1)
          } else {
            setVisible(false)
          }
        }
      })
    }
    return () => {
      componentMounted = false
    }
  }, [pop_status])

  return (
    visible && (
      <>
        <ModalBackground onClick={handleToggle} />
        <ModalContainer>
          <ModalClose onClick={handleToggle} />
          <ModalTitle>Hey!</ModalTitle>
          <ModalContent>
            Wait a sec, before you leave, it's worth to book free consultatlion
            with our expert.
          </ModalContent>
          <BaseButton id="popup-button" to="/free-project-estimation">
            free consultation
          </BaseButton>
        </ModalContainer>
      </>
    )
  )
}

export default Popup
