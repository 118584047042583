import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import SliderControls from "./sliderControls"
import { useSwiper } from "../hooks/useSwiper"

const Slide = styled.li`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

const Slider = styled.div`
  width: 100%;
  display: flex;
`

const SlideContent = styled.div`
  max-width: 440px;
  padding: 2.25rem 1rem;
  margin: 0 auto;
`

const SliderWrapper = styled.div`
  ${(props) =>
    !props.showMultipleClients &&
    css`
      width: 40%;
      margin-left: auto;
      @media screen and (max-width: 1195px) {
        width: 100%;
      }
    `}
`

const ClientsSlider = ({ showMultipleClients }) => {
  const { allWordpressWpClient } = useStaticQuery(
    graphql`
      query {
        allWordpressWpClient(limit: 40) {
          nodes {
            title
            client_taxonomy
            featured_media {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    `
  )
  let activeClients = allWordpressWpClient.nodes.filter(
    (client) => client.client_taxonomy != 16
  )

  const { swiperElement, swiperInstance, activeSlideIndex } = useSwiper(
    activeClients,
    {
      breakpoints: showMultipleClients
        ? {
            1195: {
              slidesPerView:
                activeClients.length >= 4 ? 4 : activeClients.length,
            },
            900: {
              slidesPerView:
                activeClients.length == 3 ? 3 : activeClients.length,
            },
            769: {
              slidesPerView:
                activeClients.length == 2 ? 2 : activeClients.length,
            },
          }
        : null,
      slidesPerView: 1,
    }
  )

  return (
    <SliderWrapper showMultipleClients={showMultipleClients}>
      <Slider>
        <div className="swiper-container" ref={swiperElement}>
          <ul style={{ margin: "0" }} className="swiper-wrapper">
            {activeClients.map((client) => {
              const { title, featured_media } = client
              if (featured_media.localFile === null) return null
              return (
                <Slide className="swiper-slide" key={title}>
                  <SlideContent>
                    <img
                      style={{ maxHeight: "200px" }}
                      src={featured_media.localFile.publicURL}
                      alt={title}
                    />
                  </SlideContent>
                </Slide>
              )
            })}
          </ul>
        </div>
      </Slider>
      <SliderControls
        swiperInstance={swiperInstance}
        activeSlideIndex={activeSlideIndex}
        slides={activeClients}
      />
    </SliderWrapper>
  )
}
export default ClientsSlider
