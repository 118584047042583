import React from "react"
import styled, { css } from "styled-components"
import { BigMobileFont } from "./typography"
import BaseImg from "./baseImg"

const Section = styled.section`
  position: relative;
`

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  margin: 30px auto;
  max-width: 85vw;
  position: relative;
  @media (min-width: 1300px) {
    max-width: 1250px;
    ${(props) =>
      props.size === "medium" &&
      css`
        max-width: 1050px;
      `}
  }

  @media screen and (max-width: 1199px) {
    justify-content: center;
  }

  @media (min-width: 1195px) {
    ${(props) =>
      props.size === "small" &&
      css`
        max-width: 850px;
      `}
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Header = styled.h2`
  margin-bottom: 0;
  max-width: 27rem;

  @media screen and (max-width: 1299px) {
    flex-basis: 36%;
  }

  @media screen and (max-width: 1199px) {
    flex-basis: 34%;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    margin: 0;
    transform: none;
  }
`

const Subheader = styled.h3`
  font-family: "Hind";
  font-size: 1rem;
  margin-bottom: 0.5rem;
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 22.562rem;
  height: 33.812rem;

  @media screen and (max-width: 1600px) {
    width: 18.187rem;
  }

  @media screen and (max-width: 1199px) {
    display: none;
  }

  > div {
    height: 100%;
  }

  img {
    object-position: 0 50% !important;
    margin: 0;
  }
`

const Box = styled.div`
  font-family: "Hind";
  line-height: 1.625em;
  border: 1px solid ${(props) => props.theme.secondary};
  border-left: none;
  position: relative;
  max-width: 36.75rem;
  padding: 3.375rem 2rem 3.375rem 4.875rem;
  margin-left: -9rem;
  @media screen and (max-width: 1299px) {
    flex-basis: 46%;
    margin-left: -4.5rem;
  }
  @media screen and (max-width: 1199px) {
    flex-basis: 66%;
  }
  @media screen and (max-width: 768px) {
    margin: 1.5rem auto 0;
    padding: 1.5rem 1.75rem;
    align-self: stretch;
    border-left: 1px solid ${(props) => props.theme.secondary};
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 25%;
    border-left: 1px solid ${(props) => props.theme.secondary};

    @media screen and (max-width: 768px) {
      border: none;
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
`

const ValuesSection = ({ title, subtitle, text, image }) => {
  return (
    <Section>
      <Wrapper>
        <Header>
          <BigMobileFont>{title}</BigMobileFont>
        </Header>
        <Box>
          <div>
            <Subheader>{subtitle}</Subheader>
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </Box>
      </Wrapper>
      <ImageWrapper>
        <BaseImg localFile={image} />
      </ImageWrapper>
    </Section>
  )
}

export default ValuesSection
